html {
  height: 100%;
}

.browserNotSupportedContainer {
  text-align: center;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 0 20px;
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
}

.browserNotSupportedContainer .infoBox {
  display: flex;
  flex-direction: column;
}

.browserNotSupportedContainer .infoBox .description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 26px;
  margin-bottom: 16px;
}

.browserNotSupportedContainer .infoBox .browserList {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}

.browserNotSupportedContainer .infoBox .browserList img {
  max-width: 80px;
  margin: 0 12px;
}

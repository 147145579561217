.modal {
  padding: 15px 20px 15px 20px;
  border: 2px solid black;
  top: 10%;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.save_cta {
  background-color: #6c5dd3;
  padding: 9px 20px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
}

.feedback_color {
  color: #6c5dd3;
  margin: 0 16px 0 0;
}

.feedback_color_mobile {
  color: #6c5dd3;
  margin: 0 12px 0 0;
}

.feedback_input {
  width: 300px;
  margin-right: 20px;
  outline: none;
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
}
